import React, { useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import { createWidget } from '@typeform/embed';

import { getUserId, getAnonymousId } from 'context/AnalyticsProvider';
import useWindow from 'context/useWindow';

const Form = styled.div`
	iframe {
		width: 100%;
		min-height: ${p => (p.desktop ? `${p.desktop}px` : '520px')};
		border: solid 1px ${p => p.theme.colors.grey200};
		outline: none;
		border-radius: ${p => p.theme.utils.borderRadius};
		display: block;
		${p =>
			p.theme.media.smallOnly(css`
				min-height: ${p =>
					p.mobile ? `${p.mobile}px ` : '520px '} !important;
			`)}
	}
	&.fullscreen {
		> div {
			height: 100% !important;
		}
		iframe {
			min-height: inherit !important;
			height: 100% !important;
			${p =>
				p.theme.media.smallOnly(css`
					min-height: inherit !important;
				`)}
		}
	}
`;

/**
 * Represents a Typeform form
 * @param {string} form - The choen Typeform to show
 * @param {...object} props - The properties for the form
 */
export default function Typeform({ form, ...props }) {
	const { windowWidth } = useWindow();
	const wrapperRef = useRef(null);

	const isMobile = windowWidth < 768;
	const userId = getUserId();
	const anonId = getAnonymousId();
	const formId = props?.typeformId || form?.split('/').pop();

	useEffect(() => {
		if (!wrapperRef?.current) return;

		createWidget(formId, {
			container: wrapperRef.current,
			hidden: {
				user_id: userId,
				anonymous_id: anonId,
				...props?.settings?.hiddenFields,
			},
			height:
				(isMobile && props?.formHeights?.mobile) ||
				props?.formHeights?.desktop,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		props?.formHeights?.mobile,
		props?.formHeights?.desktop,
		wrapperRef,
		isMobile,
	]);

	return (
		<>
			{form && wrapperRef && (
				<Form
					ref={wrapperRef}
					id={`typeform-${props?.blockId || formId}`}
					className={`typeform-widget${
						(props?.fullscreen && ' fullscreen') || ''
					}`}
					{...props?.formHeights}
					style={props?.style}
				/>
			)}
		</>
	);
}
